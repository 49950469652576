var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
// @ts-ignore
import { fetchConsultar, fetchConsultarPaginada, fetchCadastrar, fetchAlterar, fetchDeletar, STATUS_API as STATUS, URL_API, } from '../../../api';
import { getTokenFromCookie } from '../../../services/mt_login';
export var STATUS_API = STATUS;
var ROTA_FOLHAS_MENSAIS = 'folhas-mensais';
export var cadastrarParametrosFolhaMensal = function (Dados) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FOLHAS_MENSAIS);
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getFolhasMensais = function (ano_referencia, mes_referencia, instiuicao_id, tipo_regime_id, reeducando_id, PaginationModel) { return __awaiter(void 0, void 0, void 0, function () {
    var Query, URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                Query = '';
                if (ano_referencia)
                    Query += "ano_referencia=".concat(ano_referencia, "&");
                if (mes_referencia)
                    Query += "mes_referencia=".concat(mes_referencia, "&");
                if (instiuicao_id)
                    Query += "instiuicao_id=".concat(instiuicao_id, "&");
                if (tipo_regime_id)
                    Query += "tipo_regime_id=".concat(tipo_regime_id, "&");
                if (reeducando_id)
                    Query += "reeducando_id=".concat(reeducando_id, "&");
                URL = "".concat(ROTA_FOLHAS_MENSAIS, "?").concat(Query);
                return [4 /*yield*/, fetchConsultarPaginada(URL, PaginationModel)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getFolhaMensal = function (ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_FOLHAS_MENSAIS, "/").concat(ID))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getCombosListar = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_FOLHAS_MENSAIS, "/combos-listar"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var getCombosCadastro = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchConsultar("".concat(ROTA_FOLHAS_MENSAIS, "/combos-cadastro"))];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var alterarFolhaMensal = function (Dados, ID) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetchAlterar("".concat(ROTA_FOLHAS_MENSAIS, "/").concat(ID), Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
// export const excluirParametrosFolhaMensal = async (ID: string) => {
//   const URL = `${ROTA_FOLHAS_MENSAIS}/${ID}`;
//   return await fetchDeletar(URL);
// };
// export const alterarSituacaoFolhaMensal = async (ID: string, situacao: string) => {
//   const URL = `${ROTA_FOLHAS_MENSAIS}/${ID}/alterar-situacao?situacao=${situacao}`;
//   return await fetchAlterar(URL);
// };
export var abrirFolhaPagamento = function (Mes, Ano) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FOLHAS_MENSAIS, "/abrir-folha-pagamento/").concat(Mes, "/").concat(Ano);
                return [4 /*yield*/, fetchAlterar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var fecharFolhaPagamento = function (Mes, Ano) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FOLHAS_MENSAIS, "/fechar-folha-pagamento/").concat(Mes, "/").concat(Ano);
                return [4 /*yield*/, fetchAlterar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var adicionarLancamentoManual = function (ContratoID, RubricaID, Valor, AnoReferencia, MesReferencia) { return __awaiter(void 0, void 0, void 0, function () {
    var Dados, URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                Dados = {
                    contrato_id: ContratoID,
                    rubrica_id: RubricaID,
                    valor: Valor,
                    ano_referencia: AnoReferencia,
                    mes_referencia: MesReferencia,
                };
                URL = "".concat(ROTA_FOLHAS_MENSAIS, "/add-lancamento/").concat(ContratoID, "}");
                return [4 /*yield*/, fetchCadastrar(URL, Dados)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
export var removerLancamentoManual = function (LancamentoID) { return __awaiter(void 0, void 0, void 0, function () {
    var URL;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                URL = "".concat(ROTA_FOLHAS_MENSAIS, "/remover-lancamento/").concat(LancamentoID);
                return [4 /*yield*/, fetchDeletar(URL)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
// export const alterarSituacaoFolha = async (AnoReferencia: number, MesReferencia: number, Situacao: string) => {
//   const Dados = {
//     ano_referencia: AnoReferencia,
//     mes_referencia: MesReferencia,
//     situacao: Situacao,
//   };
//   const URL = `${ROTA_FOLHAS_MENSAIS}/alterar-situacao`;
//   return await fetchAlterar(URL, Dados);
// };
export var getRelatorioContrato = function (ContratoID, AnoReferencia, MesReferencia) { return __awaiter(void 0, void 0, void 0, function () {
    var Query, URL, TOKEN, response, pdfBlob, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                Query = '';
                Query += "ano_referencia=".concat(AnoReferencia, "&");
                Query += "mes_referencia=".concat(MesReferencia, "&");
                Query += "contrato_id=".concat(ContratoID, "&");
                URL = "".concat(URL_API, "/").concat(ROTA_FOLHAS_MENSAIS, "/relatorio-folha/?").concat(Query);
                TOKEN = getTokenFromCookie();
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch(URL, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                            'Content-Type': 'application/pdf',
                        },
                    })];
            case 2:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error('Failed to fetch PDF');
                }
                return [4 /*yield*/, response.blob()];
            case 3:
                pdfBlob = _a.sent();
                // Cria uma URL temporária para o Blob
                return [2 /*return*/, window.URL.createObjectURL(pdfBlob)];
            case 4:
                error_1 = _a.sent();
                console.error('Erro ao gerar o PDF:', error_1);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
export var getRelatorioFolha = function (AnoReferencia, MesReferencia, InstituicaoID, TipoRegimeID, ReeducandoID) { return __awaiter(void 0, void 0, void 0, function () {
    var Query, URL, TOKEN, response, pdfBlob, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                Query = '';
                Query += "ano_referencia=".concat(AnoReferencia, "&");
                Query += "mes_referencia=".concat(MesReferencia, "&");
                if (InstituicaoID)
                    Query += "instituicao_id=".concat(InstituicaoID, "&");
                if (TipoRegimeID)
                    Query += "tipo_regime_id=".concat(TipoRegimeID, "&");
                if (ReeducandoID)
                    Query += "reeducando_id=".concat(ReeducandoID, "&");
                URL = "".concat(URL_API, "/").concat(ROTA_FOLHAS_MENSAIS, "/relatorio-folha/?").concat(Query);
                TOKEN = getTokenFromCookie();
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch(URL, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                            'Content-Type': 'application/pdf',
                        },
                    })];
            case 2:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error('Failed to fetch PDF');
                }
                return [4 /*yield*/, response.blob()];
            case 3:
                pdfBlob = _a.sent();
                // Cria uma URL temporária para o Blob
                return [2 /*return*/, window.URL.createObjectURL(pdfBlob)];
            case 4:
                error_2 = _a.sent();
                console.error('Erro ao gerar o PDF:', error_2);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
export var getResumoFolha = function (AnoReferencia, MesReferencia, InstituicaoID, TipoRegimeID, ReeducandoID) { return __awaiter(void 0, void 0, void 0, function () {
    var Query, URL, TOKEN, response, pdfBlob, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                Query = '';
                Query += "ano_referencia=".concat(AnoReferencia, "&");
                Query += "mes_referencia=".concat(MesReferencia, "&");
                if (InstituicaoID)
                    Query += "instituicao_id=".concat(InstituicaoID, "&");
                if (TipoRegimeID)
                    Query += "tipo_regime_id=".concat(TipoRegimeID, "&");
                if (ReeducandoID)
                    Query += "reeducando_id=".concat(ReeducandoID, "&");
                URL = "".concat(URL_API, "/").concat(ROTA_FOLHAS_MENSAIS, "/resumo-folha/?").concat(Query);
                TOKEN = getTokenFromCookie();
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch(URL, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                            'Content-Type': 'application/pdf',
                        },
                    })];
            case 2:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error('Failed to fetch PDF');
                }
                return [4 /*yield*/, response.blob()];
            case 3:
                pdfBlob = _a.sent();
                // Cria uma URL temporária para o Blob
                return [2 /*return*/, window.URL.createObjectURL(pdfBlob)];
            case 4:
                error_3 = _a.sent();
                console.error('Erro ao gerar o PDF:', error_3);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };
export var getRelatorioAusentes = function (AnoReferencia, MesReferencia, InstituicaoID, TipoRegimeID, ReeducandoID) { return __awaiter(void 0, void 0, void 0, function () {
    var Query, URL, TOKEN, response, pdfBlob, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                Query = '';
                Query += "ano_referencia=".concat(AnoReferencia, "&");
                Query += "mes_referencia=".concat(MesReferencia, "&");
                if (InstituicaoID)
                    Query += "instituicao_id=".concat(InstituicaoID, "&");
                if (TipoRegimeID)
                    Query += "tipo_regime_id=".concat(TipoRegimeID, "&");
                if (ReeducandoID)
                    Query += "reeducando_id=".concat(ReeducandoID, "&");
                URL = "".concat(URL_API, "/").concat(ROTA_FOLHAS_MENSAIS, "/relatorio-ausentes-folha/?").concat(Query);
                TOKEN = getTokenFromCookie();
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 5]);
                return [4 /*yield*/, fetch(URL, {
                        method: 'GET',
                        headers: {
                            Authorization: "Bearer ".concat(TOKEN),
                            'Content-Type': 'application/pdf',
                        },
                    })];
            case 2:
                response = _a.sent();
                if (!response.ok) {
                    throw new Error('Failed to fetch PDF');
                }
                return [4 /*yield*/, response.blob()];
            case 3:
                pdfBlob = _a.sent();
                // Cria uma URL temporária para o Blob
                return [2 /*return*/, window.URL.createObjectURL(pdfBlob)];
            case 4:
                error_4 = _a.sent();
                console.error('Erro ao gerar o PDF:', error_4);
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}); };

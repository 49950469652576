var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*eslint no-useless-escape: 0*/
import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, MenuItem, Grid, useMediaQuery, useTheme, } from '@mui/material';
import { MENSAGENS, } from '../../../utils';
import { ROUTES, PAGINAS } from '../../../routes';
import ContainerPainel from '../../../components/container_painel';
import AuthContext from '../../../contexts/auth';
import { BoxPrincipal, BoxBotoes } from '../../../components/form_box';
import Loading from '../../../components/loading';
import { useNavigate, useParams } from 'react-router-dom';
import { BotaoSalvar, BotaoFechar } from '../../../components/botoes';
import InputText, { InputDados } from '../../../components/input_dados';
import { alterarRubricasFolha, getRubricasFolhas, STATUS_API } from './rubricas_folha.service';
export var RubricasFolhasEditar = function (props) {
    var _a = useContext(AuthContext) || { DEV: false, addMensagem: function (mensagem) { }, getAcoesFuncionalidade: function () { return []; } }, DEV = _a.DEV, addMensagem = _a.addMensagem, getAcoesFuncionalidade = _a.getAcoesFuncionalidade;
    var _b = React.useState(getAcoesFuncionalidade(PAGINAS.RUBRICAS_FOLHAS)), AcoesFuncionalidade = _b[0], setAcoesFuncionalidade = _b[1];
    var navigate = useNavigate();
    var _c = useState(true), loading = _c[0], setLoading = _c[1];
    var _d = useState(true), LoadingInicio = _d[0], setLoadingInicio = _d[1];
    var _e = useParams(), id = _e.id, modo = _e.modo;
    var _f = useState(), RubricasFolha = _f[0], setRubricasFolha = _f[1];
    var _g = useState(), DescricaoBonus = _g[0], setDescricaoBonus = _g[1];
    var _h = useState(), DescricaoHoraExtra = _h[0], setDescricaoHoraExtra = _h[1];
    var _j = useState(), DescricaoPensao = _j[0], setDescricaoPensao = _j[1];
    var _k = useState(), DescricaoSalario = _k[0], setDescricaoSalario = _k[1];
    var _l = useState(), DescricaoValeAlimentacao = _l[0], setDescricaoValeAlimentacao = _l[1];
    var _m = useState(), DescricaoValeTransporte = _m[0], setDescricaoValeTransporte = _m[1];
    var _o = useState(), DescricaoPeculio = _o[0], setDescricaoPeculio = _o[1];
    var _p = useState(false), BonusPermiteLancamentoManual = _p[0], setBonusPermiteLancamentoManual = _p[1];
    var _q = useState(false), HoraExtraPermiteLancamentoManual = _q[0], setHoraExtraPermiteLancamentoManual = _q[1];
    var _r = useState(false), PensaoPermiteLancamentoManual = _r[0], setPensaoPermiteLancamentoManual = _r[1];
    var _s = useState(false), SalarioPermiteLancamentoManual = _s[0], setSalarioPermiteLancamentoManual = _s[1];
    var _t = useState(false), ValeAlimentacaoPermiteLancamentoManual = _t[0], setValeAlimentacaoPermiteLancamentoManual = _t[1];
    var _u = useState(false), ValeTransportePermiteLancamentoManual = _u[0], setValeTransportePermiteLancamentoManual = _u[1];
    var _v = useState(false), PeculioPermiteLancamentoManual = _v[0], setPeculioPermiteLancamentoManual = _v[1];
    var _w = useState('Ativo'), SituacaoBonus = _w[0], setSituacaoBonus = _w[1];
    var _x = useState('Ativo'), SituacaoHoraExtra = _x[0], setSituacaoHoraExtra = _x[1];
    var _y = useState('Ativo'), SituacaoPensao = _y[0], setSituacaoPensao = _y[1];
    var _z = useState('Ativo'), SituacaoSalario = _z[0], setSituacaoSalario = _z[1];
    var _0 = useState('Ativo'), SituacaoValeAlimentacao = _0[0], setSituacaoValeAlimentacao = _0[1];
    var _1 = useState('Ativo'), SituacaoValeTransporte = _1[0], setSituacaoValeTransporte = _1[1];
    var _2 = useState('Ativo'), SituacaoValePeculio = _2[0], setSituacaoValePeculio = _2[1];
    var theme = useTheme();
    var isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    var DESABILITAR_CAMPOS = !AcoesFuncionalidade.ALTERAR;
    useEffect(function () {
        carregarDados();
        return function () { };
    }, []);
    var carregarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var RubricasFolhaAux, bonus, hora_extra, pensao_alimenticia, salario, vale_alimentacao, vale_transporte, peculio;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getRubricasFolhas()];
                case 1:
                    RubricasFolhaAux = _a.sent();
                    if (RubricasFolhaAux.Status == STATUS_API.ERRO) {
                        alert(RubricasFolhaAux.Mensagem);
                        return [2 /*return*/];
                    }
                    else {
                        bonus = RubricasFolhaAux.bonus, hora_extra = RubricasFolhaAux.hora_extra, pensao_alimenticia = RubricasFolhaAux.pensao_alimenticia, salario = RubricasFolhaAux.salario, vale_alimentacao = RubricasFolhaAux.vale_alimentacao, vale_transporte = RubricasFolhaAux.vale_transporte, peculio = RubricasFolhaAux.peculio;
                        if (bonus) {
                            setDescricaoBonus(bonus.descricao);
                            setBonusPermiteLancamentoManual(bonus.permite_lancamento_manual);
                            setSituacaoBonus(bonus.situacao);
                        }
                        if (hora_extra) {
                            setDescricaoHoraExtra(hora_extra.descricao);
                            setHoraExtraPermiteLancamentoManual(hora_extra.permite_lancamento_manual);
                            setSituacaoHoraExtra(hora_extra.situacao);
                        }
                        if (pensao_alimenticia) {
                            setDescricaoPensao(pensao_alimenticia.descricao);
                            setPensaoPermiteLancamentoManual(pensao_alimenticia.permite_lancamento_manual);
                            setSituacaoPensao(pensao_alimenticia.situacao);
                        }
                        if (salario) {
                            setDescricaoSalario(salario.descricao);
                            setSalarioPermiteLancamentoManual(salario.permite_lancamento_manual);
                            setSituacaoSalario(salario.situacao);
                        }
                        if (vale_alimentacao) {
                            setDescricaoValeAlimentacao(vale_alimentacao.descricao);
                            setValeAlimentacaoPermiteLancamentoManual(vale_alimentacao.permite_lancamento_manual);
                            setSituacaoValeAlimentacao(vale_alimentacao.situacao);
                        }
                        if (vale_transporte) {
                            setDescricaoValeTransporte(vale_transporte.descricao);
                            setValeTransportePermiteLancamentoManual(vale_transporte.permite_lancamento_manual);
                            setSituacaoValeTransporte(vale_transporte.situacao);
                        }
                        if (peculio) {
                            setDescricaoPeculio(peculio.descricao);
                            setPeculioPermiteLancamentoManual(peculio.permite_lancamento_manual);
                            setSituacaoValePeculio(peculio.situacao);
                        }
                        setRubricasFolha(RubricasFolhaAux);
                    }
                    setLoadingInicio(false);
                    setLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var validarDados = function () {
        if (!DescricaoBonus || DescricaoBonus.trim() == '') {
            alert("Descri\u00E7\u00E3o do B\u00F4nus' ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!DescricaoHoraExtra || DescricaoHoraExtra.trim() == '') {
            alert("Descri\u00E7\u00E3o da Hora Extra' ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!DescricaoPensao || DescricaoPensao.trim() == '') {
            alert("Descri\u00E7\u00E3o da Pens\u00E3o Aliment\u00EDcia' ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!DescricaoSalario || DescricaoSalario.trim() == '') {
            alert("Descri\u00E7\u00E3o do Sal\u00E1rio' ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!DescricaoValeAlimentacao || DescricaoValeAlimentacao.trim() == '') {
            alert("Descri\u00E7\u00E3o do Vale Alimenta\u00E7\u00E3o' ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!DescricaoValeTransporte || DescricaoValeTransporte.trim() == '') {
            alert("Descri\u00E7\u00E3o do Vale Transporte' ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        if (!DescricaoPeculio || DescricaoPeculio.trim() == '') {
            alert("Descri\u00E7\u00E3o do Pec\u00FAlio' ".concat(MENSAGENS.PREENCHIMENTO_OBRIGATORIO));
            return false;
        }
        return true;
    };
    var salvarDados = function () { return __awaiter(void 0, void 0, void 0, function () {
        var Dados, Resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validarDados())
                        return [2 /*return*/];
                    setLoading(true);
                    Dados = {
                        bonus: {
                            descricao: DescricaoBonus,
                            permite_lancamento_manual: BonusPermiteLancamentoManual,
                            situacao: SituacaoBonus,
                        },
                        hora_extra: {
                            descricao: DescricaoHoraExtra,
                            permite_lancamento_manual: HoraExtraPermiteLancamentoManual,
                            situacao: SituacaoHoraExtra,
                        },
                        pensao_alimenticia: {
                            descricao: DescricaoPensao,
                            permite_lancamento_manual: PensaoPermiteLancamentoManual,
                            situacao: SituacaoPensao,
                        },
                        salario: {
                            descricao: DescricaoSalario,
                            permite_lancamento_manual: SalarioPermiteLancamentoManual,
                            situacao: SituacaoSalario,
                        },
                        vale_alimentacao: {
                            descricao: DescricaoValeAlimentacao,
                            permite_lancamento_manual: ValeAlimentacaoPermiteLancamentoManual,
                            situacao: SituacaoValeAlimentacao,
                        },
                        vale_transporte: {
                            descricao: DescricaoValeTransporte,
                            permite_lancamento_manual: ValeTransportePermiteLancamentoManual,
                            situacao: SituacaoValeTransporte,
                        },
                        peculio: {
                            descricao: DescricaoPeculio,
                            permite_lancamento_manual: PeculioPermiteLancamentoManual,
                            situacao: SituacaoValePeculio,
                        },
                    };
                    return [4 /*yield*/, alterarRubricasFolha(Dados)];
                case 1:
                    Resp = _a.sent();
                    setLoading(false);
                    if (Resp && Resp.Status == 'OK') {
                        alert(MENSAGENS.REGISTRO_ALTERADO_SUCESSO);
                    }
                    else if (Resp) {
                        alert(Resp.Mensagem);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    // const editarRegistro = async () => {
    //   navigate(`${ROUTES.RUBRICAS_FOLHAS}/${id}/${MODOS.ALTERAR}`);
    // };
    return (_jsx(ContainerPainel, __assign({ modo: modo, pagina_acima: PAGINAS.RUBRICAS_FOLHAS, link_pagina_acima: ROUTES.RUBRICAS_FOLHAS }, { children: _jsxs(BoxPrincipal, __assign({ sx: { width: '50%' } }, { children: [!LoadingInicio && (_jsxs(_Fragment, { children: [_jsxs(Box, __assign({ sx: { mt: 4 } }, { children: [_jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "B\u00F4nus" })), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputText, { label: 'Descri\u00E7\u00E3o', value: DescricaoBonus, disabled: DESABILITAR_CAMPOS, onChange: function (event) { return setDescricaoBonus(event.target.value); } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'BonusPermiteLancamentoManual', label: 'Permite Lan\u00E7amento Manual', value: BonusPermiteLancamentoManual, onChange: function (event) {
                                                    setBonusPermiteLancamentoManual(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao_bonus', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: SituacaoBonus, onChange: function (event) {
                                                    setSituacaoBonus(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputDados, { label: 'Tipo de R\u00FAbrica', value: 'PROVENTO', disabled: true }) }))] })), _jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Hora Extra" })), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputText, { label: 'Descri\u00E7\u00E3o', value: DescricaoHoraExtra, disabled: DESABILITAR_CAMPOS, onChange: function (event) { return setDescricaoHoraExtra(event.target.value); } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'HoraExtraPermiteLancamentoManual', label: 'Permite Lan\u00E7amento Manual', value: HoraExtraPermiteLancamentoManual, onChange: function (event) {
                                                    setHoraExtraPermiteLancamentoManual(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao_hora_extra', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: SituacaoHoraExtra, onChange: function (event) {
                                                    setSituacaoHoraExtra(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputDados, { label: 'Tipo de R\u00FAbrica', value: 'PROVENTO', disabled: true }) }))] })), _jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Pens\u00E3o Aliment\u00EDcia" })), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputText, { label: 'Descri\u00E7\u00E3o', value: DescricaoPensao, disabled: DESABILITAR_CAMPOS, onChange: function (event) { return setDescricaoPensao(event.target.value); } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'PensaoPermiteLancamentoManual', label: 'Permite Lan\u00E7amento Manual', value: PensaoPermiteLancamentoManual, onChange: function (event) {
                                                    setPensaoPermiteLancamentoManual(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao_pensao', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: SituacaoPensao, onChange: function (event) {
                                                    setSituacaoPensao(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputDados, { label: 'Tipo de R\u00FAbrica', value: 'DESCONTO', disabled: true }) }))] })), _jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Sal\u00E1rio" })), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputText, { label: 'Descri\u00E7\u00E3o', value: DescricaoSalario, disabled: DESABILITAR_CAMPOS, onChange: function (event) { return setDescricaoSalario(event.target.value); } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'SalarioPermiteLancamentoManual', label: 'Permite Lan\u00E7amento Manual', value: SalarioPermiteLancamentoManual, onChange: function (event) {
                                                    setSalarioPermiteLancamentoManual(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao_salario', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: SituacaoSalario, onChange: function (event) {
                                                    setSituacaoSalario(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputDados, { label: 'Tipo de R\u00FAbrica', value: 'PROVENTO', disabled: true }) }))] })), _jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Vale Alimenta\u00E7\u00E3o" })), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputText, { label: 'Descri\u00E7\u00E3o', value: DescricaoValeAlimentacao, disabled: DESABILITAR_CAMPOS, onChange: function (event) { return setDescricaoValeAlimentacao(event.target.value); } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'ValeAlimentacaoPermiteLancamentoManual', label: 'Permite Lan\u00E7amento Manual', value: ValeAlimentacaoPermiteLancamentoManual, onChange: function (event) {
                                                    setValeAlimentacaoPermiteLancamentoManual(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao_vale_alimentacao', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: SituacaoValeAlimentacao, onChange: function (event) {
                                                    setSituacaoValeAlimentacao(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputDados, { label: 'Tipo de R\u00FAbrica', value: 'PROVENTO', disabled: true }) }))] })), _jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Vale Transporte" })), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputText, { label: 'Descri\u00E7\u00E3o', value: DescricaoValeTransporte, disabled: DESABILITAR_CAMPOS, onChange: function (event) { return setDescricaoValeTransporte(event.target.value); } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'ValeTransportePermiteLancamentoManual', label: 'Permite Lan\u00E7amento Manual', value: ValeTransportePermiteLancamentoManual, onChange: function (event) {
                                                    setValeTransportePermiteLancamentoManual(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao_vale_transporte', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: SituacaoValeTransporte, onChange: function (event) {
                                                    setSituacaoValeTransporte(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputDados, { label: 'Tipo de R\u00FAbrica', value: 'PROVENTO', disabled: true }) }))] })), _jsx(Typography, __assign({ variant: 'h6', gutterBottom: true }, { children: "Pec\u00FAlio" })), _jsxs(Grid, __assign({ container: true, spacing: 2, mb: 2 }, { children: [_jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputText, { label: 'Descri\u00E7\u00E3o', value: DescricaoPeculio, disabled: DESABILITAR_CAMPOS, onChange: function (event) { return setDescricaoPeculio(event.target.value); } }) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'PeculioPermiteLancamentoManual', label: 'Permite Lan\u00E7amento Manual', value: PeculioPermiteLancamentoManual, onChange: function (event) {
                                                    setPeculioPermiteLancamentoManual(event.target.value === 'true');
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'true' }, { children: "Sim" })), _jsx(MenuItem, __assign({ value: 'false' }, { children: "N\u00E3o" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 3 }, { children: _jsxs(InputDados, __assign({ select: true, id: 'situacao_peculio', label: 'Situa\u00E7\u00E3o', placeholder: 'Situa\u00E7\u00E3o', value: SituacaoValePeculio, onChange: function (event) {
                                                    setSituacaoValePeculio(event.target.value);
                                                }, required: true, disabled: DESABILITAR_CAMPOS }, { children: [_jsx(MenuItem, __assign({ value: 'Ativo' }, { children: "Ativo" })), _jsx(MenuItem, __assign({ value: 'Inativo' }, { children: "Inativo" }))] })) })), _jsx(Grid, __assign({ item: true, xs: 12, sm: 12 }, { children: _jsx(InputDados, { label: 'Tipo de R\u00FAbrica', value: 'PROVENTO', disabled: true }) }))] }))] })), _jsxs(BoxBotoes, { children: [AcoesFuncionalidade.ALTERAR && _jsx(BotaoSalvar, { onClick: salvarDados }), _jsx(BotaoFechar, { modo: modo })] })] })), loading && _jsx(Loading, {})] })) })));
};
export default RubricasFolhasEditar;
